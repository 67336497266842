import { useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import type { Feature } from './features';
import { MentiError } from './MentiError';

export function usePageError({
  error,
  message,
  feature,
}: {
  error: Error;
  message: string;
  feature: Feature;
}) {
  useEffect(() => {
    if (error instanceof MentiError) {
      captureException(error);
    } else {
      captureException(
        new MentiError(message, {
          cause: error,
          feature,
        }),
      );
    }
  }, [error, message, feature]);
}
