'use client';
import React from 'react';
import { usePageError } from '@mentimeter/errors/usePageError';
import { GeneralError } from 'src/components/Error';

export default function Error({ error }: { error: Error }) {
  usePageError({
    error,
    message: 'Something went wrong and got caught in root-error',
    feature: 'root-error',
  });

  return <GeneralError />;
}
